// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useEffect } from 'react';

type Result = void | (() => void | undefined);

/**
 * This custom hook is a wrapper around `useEffect()` that allows the direct use of an async function
 * as the effect function.
 */
export function useAsyncEffect(asyncEffect: () => Promise<Result>, dependencies: readonly any[]) {
  useEffect(
    () => {
      let result: Result;

      asyncEffect().then(r => result = r);

      return () => {
        if (typeof result === 'function') {
          result();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );
}
