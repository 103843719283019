// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import Percentile from '../../models/Percentile';
import { getReadableTime } from '../../utils/date';
import { mergeTimeEvaluation } from '../../utils/metrics';
import styles from './MergeTime.module.css';

export function getHighlight(average: string) {
  const readableAverage = getReadableTime(average);
  const hours = readableAverage.match(/[0-9]+\shour(s?)/) && readableAverage.match(/[0-9]+\shour(s?)/)![0];

  const benchmark = mergeTimeEvaluation(hours || "0");

  switch (benchmark) {
    case 'ELITE':
      return styles.averageEliteMergeTime;
    case 'STRONG':
      return styles.averageStrongMergeTime;
    case 'FAIR':
      return styles.averageFairMergeTime;
    case 'NEEDS FOCUS':
      return styles.averageNeedsFocusMergeTime;
  }
}

interface AverageMergeTimeProps {
  average: string;
}

export function AverageMergeTime(props: AverageMergeTimeProps) {
  const { average } = props;
  const readableAverage = getReadableTime(average);
  const hours = readableAverage.match(/[0-9]+\shour(s?)/) && readableAverage.match(/[0-9]+\shour(s?)/)![0]
  const minutes = readableAverage.match(/[0-9]+\sminute(s?)/) && readableAverage.match(/[0-9]+\sminute(s?)/)![0]

  return (
    <div className={styles.averageMergeTime}>
      {hours && (
        <Typography variant="h4">{hours}</Typography>
      )}
      {minutes && (
        <Typography variant="h4">{minutes}</Typography>
      )}
    </div>
  )
}

interface MergeTimeByPercentileProps {
  byPercentile: Record<Percentile, string>;
}

export function MergeTimeByPercentile(props: MergeTimeByPercentileProps) {
  const { byPercentile } = props;
  return (
    <Table className={styles.container}>
      <TableHead>
        <TableRow>
          <TableCell>Percentile</TableCell>
          <TableCell>Merge time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(byPercentile).map(([percentile, time]) => (
          <TableRow key={percentile}>
            <TableCell>
              <b>{percentile}</b>
            </TableCell>
            <TableCell>
              {getReadableTime(time)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
