// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import SizeDetails from '../../models/SizeDetails';
import { sizeEvaluation } from '../../utils/metrics';
import styles from './AverageSize.module.css';

interface AverageSizeProps {
  averageSize: SizeDetails;
}

export default function AverageSize(props: AverageSizeProps) {
  const { averageSize } = props;
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell variant='head'>Total additions</TableCell>
          <TableCell align='right'>
            {Math.trunc(averageSize.additions)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head'>Total deletions</TableCell>
          <TableCell align='right'>
            {Math.trunc(averageSize.deletions)}
          </TableCell>
        </TableRow>
        <TableRow className={styles.row} data-metrics={sizeEvaluation(Math.trunc(averageSize.additions + averageSize.deletions))}>
          <TableCell className={styles.cell} variant='head'>Size (additions + deletions)</TableCell>
          <TableCell className={styles.cell} align='right'>
            {Math.trunc(averageSize.additions + averageSize.deletions)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head'>Total changed files</TableCell>
          <TableCell align='right'>
            {Math.trunc(averageSize.files)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
