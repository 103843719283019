// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

interface CopyrightProps {
  className?: string;
}


export default React.memo(function Copyright(props: CopyrightProps) {
  const { className } = props;
  return (
    <Typography className={className} variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://reviewpad.com/">
        Reviewpad
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
})
