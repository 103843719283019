// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Container, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import AnalysisSummary from '../../models/AnalysisSummary';
import { getReadableDate } from '../../utils/date';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styles from './AnalysisReportMetaData.module.css';

interface AnalysisReportMetaDataProps {
  analysisSummary: AnalysisSummary;
}

export default function AnalysisReportMetaData(props: AnalysisReportMetaDataProps) {
  const { analysisSummary } = props;
  return (
    <Container disableGutters>
      <Typography textAlign='center'>
        <b>Last {analysisSummary.totalPRs} merged pull requests</b>
      </Typography>
      <Stepper orientation='horizontal' alternativeLabel>
        <Step>
          <StepLabel StepIconComponent={FiberManualRecordIcon} classes={{ labelContainer: styles.labelContainer }}>
            <Paper className={styles.dateTag} elevation={0}>
              <Typography variant='body2'><strong>From</strong></Typography>
              <Typography variant='body1'>{getReadableDate(analysisSummary.dateFrom)}</Typography>
            </Paper>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={FiberManualRecordIcon} classes={{ labelContainer: styles.labelContainer }}>
            <Paper className={styles.dateTag} elevation={0}>
              <Typography variant='body2'><strong>To</strong></Typography>
              <Typography variant='body1'>{getReadableDate(analysisSummary.dateTo)}</Typography>
            </Paper>
          </StepLabel>
        </Step>
      </Stepper>
    </Container>
  );
}
