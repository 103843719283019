// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AnalysisSummary from '../../models/AnalysisSummary';
import AnalysisReportMetaData from '../AnalysisReportMetaData';
import AverageSize from '../AverageSize';
import TotalFilesByPRsCountChart from '../Charts/TotalFilesByPRsCountChart';
import TotalPRsByReviewersCountChart from '../Charts/TotalPRsByReviewersCountChart';
import TotalReviewsByCommentCountChart from '../Charts/TotalReviewsByCommentCountChart';
import ContainerTitle from '../ContainerTitle';
import HotspotFiles from '../HotspotFiles';
import { AverageMergeTime, getHighlight, MergeTimeByPercentile } from '../MergeTime/MergeTime';
import MetricsBenchmarks from '../MetricsBenchmarks';
import Result from '../Result';
import SectionTitle from '../SectionTitle';
import SizeByPercentile from '../SizeByPercentile';
import { AverageTimeByCycle, TimeByCycleByPercentile } from '../TimeByCycle/TimeByCycle';
import styles from './AnalysisReport.module.css';

interface AnalysisProps {
  repositoryUrl: string;
  analysisSummary: AnalysisSummary;
}

export default function Analysis(props: AnalysisProps) {
  const location = useLocation();
  const { repositoryUrl, analysisSummary } = props;
  const [repoOwner, repoName] = repositoryUrl
    .replace('https://github.com/', '')
    .split('/');

  useEffect(
    () => {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    },
    [location],
  );

  return (
    <div>
      <Typography
        component="h4"
        variant="h4"
        align="left"
        color="text.primary"
        gutterBottom
        className={styles.title}
      >
        Results for <a href={repositoryUrl} className={styles.repo} target='_blank' rel='noreferrer'>{repoOwner + '/' + repoName}</a>
      </Typography>
      <div className={styles.details}>
        <AnalysisReportMetaData analysisSummary={analysisSummary} />
        <SectionTitle
          id="time"
          title="Time"
          subtitle="Understand how long pull requests are taking"
        />
        <div>
          <ContainerTitle
            id="merge-time"
            title="Merge time"
            subtitle="The time a pull request takes to be merged"
          />
          <Divider />
          <div className={styles.resultContainer}>
            <Result
              title="Average"
              subtitle={
                <div className={styles.benchmarksTooltip}>
                  <Typography variant='caption'>Average merge time from the pull request creation until it's merge.</Typography>
                  <MetricsBenchmarks />
                </div>
              }
              className={getHighlight(analysisSummary.mergeTime.average)}
            >
              <AverageMergeTime average={analysisSummary.mergeTime.average} />
            </Result>
            <Result
              title="By percentile"
              subtitle="Merge time from the pull request creation until it's merge on a percentile"
            >
              <MergeTimeByPercentile byPercentile={analysisSummary.mergeTime.byPercentile} />
            </Result>
          </div>
        </div>
        <div>
          <ContainerTitle
            id="review-time"
            title="Review time"
            subtitle="Time a pull request takes to be reviewed"
          />
          <Divider />
          <div className={styles.resultContainer}>
            <Result
              title="Average"
              subtitle={
                <div className={styles.benchmarksTooltip}>
                  <Typography variant='caption'>Average review time since pull request was created</Typography>
                  <MetricsBenchmarks />
                </div>
              }
            >
              <AverageTimeByCycle averageByCycle={analysisSummary.timeByCycle.average} />
            </Result>
            <Result title="By percentile" subtitle="Review time since pull request was created by percentile">
              <TimeByCycleByPercentile byPercentile={analysisSummary.timeByCycle.byPercentile} totalIgnoredPRs={analysisSummary.timeByCycle.totalIgnoredPRs} />
            </Result>
          </div>
        </div>
        <SectionTitle
          id="size"
          title="Size"
          subtitle="Understand how big pull requests are"
        />
        <div>
          <ContainerTitle
            id="pull-request-size"
            title="Pull request size"
            subtitle="Total number of lines and files modified on a pull request"
          />
          <Divider />
          <div className={styles.resultContainer}>
            <Result
              title="Average"
              subtitle={
                <div className={styles.benchmarksTooltip}>
                  <Typography variant='caption'>Average pull request size</Typography>
                  <MetricsBenchmarks />
                </div>
              }
            >
              <AverageSize averageSize={analysisSummary.averageSize} />
            </Result>
            <Result
              title="By percentile"
              subtitle="Pull request size details by percentile"
            >
              <SizeByPercentile sizeByPercentile={analysisSummary.sizeByPercentile} />
            </Result>
          </div>
        </div>
        <SectionTitle
          id="review-rate"
          title="Review Rate"
          subtitle="Understand how many reviews and comments a pull request usually has"
        />
        <div>
          <ContainerTitle
            id="pr-count-vs-reviewers-count"
            title="Pull requests count vs Reviewers count"
            subtitle="Total number of pull requests with a certain number of reviewers"
            subtitle2="The pull request author is ignored as a reviewer."
          />
          <Divider />
          <Result className={styles.chart}>
            <TotalPRsByReviewersCountChart totalPRsByTotalReviewers={analysisSummary.totalPRsByReviewersCount} />
          </Result>
        </div>
        <div>
          <ContainerTitle
            id="review-count-vs-comment-count"
            title="Review count vs Comment count"
            subtitle="Total number reviews with a certain number of comments"
            subtitle2="Comments and reviews from the pull request author are ignored."
          />
          <Divider />
          <Result className={styles.chart}>
            <TotalReviewsByCommentCountChart totalReviewsByTotalComments={analysisSummary.totalReviewsByCommentCount} />
          </Result>
        </div>
        <SectionTitle
          id="rework-rate"
          title="Rework Rate"
          subtitle="Understand how often a file is modified"
        />
        <div>
          <ContainerTitle
            id="file-count-vs-pr-count"
            title="File count vs Pull requests count"
            subtitle="Total number of files modified only on a certain number of pull requests"
          />
          <Divider />
          <Result className={styles.chart}>
            <TotalFilesByPRsCountChart totalFilesByPRsCount={analysisSummary.totalFilesByPRsCount} />
          </Result>
        </div>
        <div>
          <ContainerTitle
            id="hotspot-files"
            title="Top modified files"
            subtitle="Top of the most modified files"
          />
          <Divider />
          <div className={clsx(styles.resultContainer, styles.resultContainerSingle)}>
            <Result>
              <HotspotFiles hotspotFiles={analysisSummary.hotspotFiles} />
            </Result>
          </div>
        </div>
      </div>
    </div>
  );
}
