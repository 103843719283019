// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

type benchmark =
  | 'ELITE'
  | 'STRONG'
  | 'FAIR'
  | 'NEEDS FOCUS';

export function mergeTimeEvaluation(hours: string): benchmark {
  const hoursDigits = Number(hours.match(/(\d+)/)![0]);
  if (hoursDigits < 13) {
    return 'ELITE';
  } else if (hoursDigits >= 13 && hoursDigits < 25) {
    return 'STRONG';
  } else if (hoursDigits >= 25 && hoursDigits <= 46) {
    return 'FAIR';
  }

  return 'NEEDS FOCUS';
}

export function reviewTimeEvaluation(hours: string): benchmark {
  const hoursDigits = Number(hours.match(/(\d+)/)![0]);
  if (hoursDigits < 13) {
    return 'ELITE';
  } else if (hoursDigits >= 13 && hoursDigits < 25) {
    return 'STRONG';
  } else if (hoursDigits >= 25 && hoursDigits <= 46) {
    return 'FAIR';
  }

  return 'NEEDS FOCUS';
}

export function sizeEvaluation(size: number): benchmark {
  if (size < 225) {
    return 'ELITE';
  } else if (size >= 225 && size < 400) {
    return 'STRONG';
  } else if (size >= 400 && size <= 800) {
    return 'FAIR';
  }

  return 'NEEDS FOCUS';
}
