// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import Percentile from '../../models/Percentile';
import SizeDetails from '../../models/SizeDetails';

interface SizeByPercentileProps {
  sizeByPercentile: Record<Percentile, SizeDetails>;
}

export default function SizeByPercentile(props: SizeByPercentileProps) {
  const { sizeByPercentile } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Percentile</TableCell>
          <TableCell align="right">Total additions</TableCell>
          <TableCell align="right">Total deletions</TableCell>
          <TableCell align="right">Size (additions + deletions)</TableCell>
          <TableCell align="right">Total changed files</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(sizeByPercentile).map(([percentile, sizeDetails]) => (
          <TableRow key={percentile}>
            <TableCell component="th" scope="row">
              <b>{Number(percentile) / 100}</b>
            </TableCell>
            <TableCell align="right">
              {Math.trunc(sizeDetails.additions)}
            </TableCell>
            <TableCell align="right">
              {Math.trunc(sizeDetails.deletions)}
            </TableCell>
            <TableCell align="right">
              {Math.trunc(sizeDetails.additions + sizeDetails.deletions)}
            </TableCell>
            <TableCell align="right">
              {Math.trunc(sizeDetails.files)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
