// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import styles from './LoadingProgress.module.css';

const progressFeedback = [
  'Taking X-Ray. Hold on!',
  'Warming up the X-Ray machine!',
  'Scanning...',
  'Still scanning...',
  'It looks like this project is quite big!',
  'Building report...',
]

export default React.memo(function LoadingProgress() {
  const [feedbackIndex, setFeedbackIndex] = useState(0);

  useEffect(
    () => {
      const interval = setInterval(
        function updateFeedback() {
          setFeedbackIndex(feedbackIndex => {
            return feedbackIndex >= progressFeedback.length - 1
              ? feedbackIndex
              : feedbackIndex + 1;
          })
        },
        4000,
      );

      return () => {
        clearInterval(interval);
      }
    },
    [],
  );

  return (
    <Container className={styles.loading}>
      <Loading />
      <Typography>{progressFeedback[feedbackIndex]}</Typography>
    </Container>
  );
});
