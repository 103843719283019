// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from "react-apexcharts";
import { plural } from '../../../utils/text';

interface TotalReviewsByTotalCommentsChartProps {
  totalReviewsByTotalComments: Record<string, number>;
}

export default React.memo(function TotalReviewsByTotalCommentsChart(props: TotalReviewsByTotalCommentsChartProps) {
  const { totalReviewsByTotalComments } = props;

  const options: ApexOptions = {
    colors: ['#005f73'],
    xaxis: {
      title: {
        text: 'Comments count',
      },
      categories: Object.keys(totalReviewsByTotalComments),
    },
    yaxis: {
      title: {
        text: 'Reviews count',
      },
    },
    tooltip: {
      x: {
        formatter: (value: number) => `Total number of reviews with <b>${value} ${plural(Number(value), 'comment')}</b>`,
      },
    },
  };

  const series: ApexOptions['series'] = [
    {
      name: "",
      data: Object.values(totalReviewsByTotalComments),
    }
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
    />
  );
});
