// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from "react-apexcharts";
import { plural } from '../../../utils/text';

interface TotalFilesByPRsCountChartProps {
  totalFilesByPRsCount: Record<string, number>;
}

export default React.memo(function TotalFilesByPRsCountChart(props: TotalFilesByPRsCountChartProps) {
  const { totalFilesByPRsCount } = props;

  const options: ApexOptions = {
    colors: ['#005f73'],
    xaxis: {
      title: {
        text: 'Pull requests count',
      },
      categories: Object.keys(totalFilesByPRsCount),
    },
    yaxis: {
      title: {
        text: 'Files count',
      },
    },
    tooltip: {
      x: {
        formatter: (value: number) => `Total number of files modified only in <b>${value} ${plural(Number(value), 'pull request')}</b>`,
      },
    },
    chart: {
      toolbar: {
        offsetX: -11,
      },
    },
  };

  const series: ApexOptions['series'] = [
    {
      name: "",
      data: Object.values(totalFilesByPRsCount),
    }
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
    />
  );
});
