// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import ServicesController from './controllers/ServicesController';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root')!;
createRoot(rootElement).render(
  // React strick mode is great to identify possible misuses of React.More info: https://reactjs.org/docs/strict-mode.html
  // However, it triggers rendering twice. More info: https://github.com/facebook/react/issues/24245
  // See also https://github.com/reactwg/react-18/discussions/19
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ServicesController>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ServicesController>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
