// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import InfoIcon from '@mui/icons-material/Info';
import { SvgIcon, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './Result.module.css';

interface ResultHeaderProps {
  title: string;
  subtitle?: ReactNode;
}

function ResultHeader(props: ResultHeaderProps) {
  const { title, subtitle } = props;
  return (
    <div className={styles.header}>
      <Typography className={styles.title}>
        {title}
      </Typography>
      {subtitle && (
        <Tooltip title={subtitle} placement="top">
          <SvgIcon color='disabled' fontSize='small'>
            <InfoIcon />
          </SvgIcon>
        </Tooltip>
      )}
    </div>
  );
}

interface ResultProps {
  title?: string;
  subtitle?: ReactNode;
  className?: string;
  children: ReactNode;
}

export default function Result(props: ResultProps) {
  const { title, subtitle, className, children } = props;
  return (
    <div className={clsx(styles.container, title && styles.containerWithTitle, className)}>
      {title && <ResultHeader title={title} subtitle={subtitle} />}
      {children}
    </div>
  );
}
