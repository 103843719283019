// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import styles from './ContainerTitle.module.css';

interface ContainerTitleProps {
  id: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
}

export default React.memo(function ContainerTitle(props: ContainerTitleProps) {
  const { id, title, subtitle, subtitle2 } = props;
  return (
    <div className={styles.container} id={id}>
      <IconButton className={styles.anchor} href={`#${id}`}>
        <LinkIcon className={styles.icon} />
      </IconButton>
      <div className={styles.header}>
        <Typography
          variant="h6"
          className={styles.title}
        >
          {title}
        </Typography>
        <Typography color="text.secondary">
          {subtitle}{subtitle2 && <sup>*</sup>}
        </Typography>
        {subtitle2 && (
          <Typography variant="caption" color="text.secondary">
            <sup>*</sup>{subtitle2}
          </Typography>
        )}
      </div>
    </div>
  );
});
