// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import Container from '@mui/material/Container';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import routes from '../../routes';
import styles from './App.module.css';

export default function App() {
  return (
    <Container className={styles.main} component="main" maxWidth={false}>
      <Routes>
        {
          routes.map((route, index) => (
            <Route key={index} {...route} />
          ))
        }
      </Routes>
      <Copyright className={styles.footer} />
    </Container>
  );
}
