// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import styles from './Landing.module.css';

interface SingleAnalysisProps {
  onComparison(): void;
}

function SingleAnalysis(props: SingleAnalysisProps) {
  const navigate = useNavigate();
  const [privateRepository, setPrivateRepository] = useState(false);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const repositoryUrl = formData.get('repositoryUrl') as string;
      const gitHubToken = formData.get('gitHubToken') as string;

      if (repositoryUrl) {
        navigate(`/analysis?repository=${encodeURIComponent(repositoryUrl)}`, {
          state: {
            gitHubToken
          }
        });
      }
    },
    [navigate],
  );

  const handleOnPrivateRepository = useCallback(
    () => setPrivateRepository(true),
    [],
  );

  const handleOnPublicRepository = useCallback(
    () => setPrivateRepository(false),
    [],
  );

  return (
    <Container
      className={styles.form}
      component="form"
      onSubmit={handleSubmit}
      disableGutters
    >
      <TextField
        required
        fullWidth
        type="url"
        id="repositoryUrl"
        name="repositoryUrl"
        label="GitHub repository url"
        autoFocus
      />
      {privateRepository && (
        <TextField
          required
          fullWidth
          id="gitHubToken"
          name="gitHubToken"
          label="GitHub token"
        />
      )}
      <Button
        type="submit"
        variant="contained"
        disableElevation
        fullWidth
      >
        Take X-Ray
      </Button>
      {!privateRepository && (
        <Typography
          className={styles.comparison}
          variant="subtitle1"
          color="textSecondary"
          onClick={handleOnPrivateRepository}
        >
          Trying to analyze a private repository?
        </Typography>
      )}
      {privateRepository && (
        <Typography
          className={styles.comparison}
          variant="subtitle1"
          color="textSecondary"
          onClick={handleOnPublicRepository}
        >
          Send me back to public repository analysis.
        </Typography>
      )}
    </Container>
  );
}

interface ComparisonAnalysisProps {
  onSingleAnalysis(): void;
}

function ComparisonAnalysis(props: ComparisonAnalysisProps) {
  const { onSingleAnalysis } = props;
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const repositoryUrlA = encodeURIComponent(formData.get('repositoryUrlA') as string);
      const repositoryUrlB = encodeURIComponent(formData.get('repositoryUrlB') as string);

      if (repositoryUrlA && repositoryUrlB) {
        navigate(`/comparison?repository=${repositoryUrlA}&repository=${repositoryUrlB}`);
      }
    },
    [navigate],
  );

  return (
    <Container
      className={styles.form}
      component="form"
      onSubmit={handleSubmit}
      disableGutters
    >
      <TextField
        required
        fullWidth
        type="url"
        id="repositoryUrlA"
        name="repositoryUrlA"
        label="GitHub repository url A"
        autoFocus
      />
      <TextField
        required
        fullWidth
        type="url"
        id="repositoryUrlB"
        name="repositoryUrlB"
        label="GitHub repository url B"
      />
      <Button
        type="submit"
        variant="contained"
        disableElevation
        fullWidth
      >
        Compare projects with X-Ray
      </Button>
      <Typography
        className={styles.comparison}
        variant="subtitle1"
        color="textSecondary"
        onClick={onSingleAnalysis}
      >
        or single analysis
      </Typography>
    </Container>
  );
}

export default function Landing() {
  const [shouldCompare, setShouldCompare] = useState(false);

  const setSingleAnalysis = useCallback(
    () => setShouldCompare(false),
    [],
  );

  const setComparisonAnalysis = useCallback(
    () => setShouldCompare(true),
    [],
  );

  return (
    <Container className={styles.container} maxWidth="sm">
      <img className={styles.logo} src={logo} alt="Reviewpad logo" />
      <Typography variant="h5" gutterBottom>
        Welcome to Reviewpad X-Ray
      </Typography>
      <Typography sx={{ color: 'text.secondary', mb: 3 }}>
        Metrics for GitHub pull requests
      </Typography>
      {
        shouldCompare
          ? <ComparisonAnalysis onSingleAnalysis={setSingleAnalysis} />
          : <SingleAnalysis onComparison={setComparisonAnalysis} />
      }
    </Container>
  );
}
