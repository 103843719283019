// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { useContext } from 'react';
import ServicesContext from '../contexts/ServicesContext';


function useServices() {
  return useContext(ServicesContext);
}

export function useAnalysisService() {
  return useServices().analysisService;
}
