// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export function plural(count: number, singular: string, plural?: string) {
  if (count === 1) {
    return singular;
  }

  return plural ? plural : `${singular}s`;
}

export function capitalize(text: string) {
  if (text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else {
    return text;
  }
}
