// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Container, Hidden, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import AnalysisReport from '../../components/AnalysisReport';
import AnalysisReportIndex from '../../components/AnalysisReportIndex';
import LoadingProgress from '../../components/LoadingProgress';
import { useAnalysisService } from '../../hooks/serviceHooks';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import AnalysisSummary from '../../models/AnalysisSummary';
import styles from './SingleAnalysis.module.css';

export default function SingleAnalysis() {
  const location = useLocation();
  const analysisService = useAnalysisService();
  const [searchParams] = useSearchParams();
  const [analysisSummary, setAnalysisSummary] = useState<AnalysisSummary>();
  const repositoryUrl = searchParams.get('repository');
  const [error, setError] = useState<Error>();

  useAsyncEffect(
    async () => {
      if (!repositoryUrl) {
        return;
      }

      try {
        const url = new URL(repositoryUrl);
        const [, repoOwner, repoName] = url.pathname.split('/');
        const gitHubToken = searchParams.get('token') || (location?.state as any)?.gitHubToken as string | undefined;
        const analysisSummary = await analysisService.getSummary(repoOwner, repoName, 50, gitHubToken);

        setAnalysisSummary(analysisSummary);
      } catch (e) {
        setError(e as any);
      }
    },
    [repositoryUrl],
  );

  if (error) {
    return <Typography>{error.stack}</Typography>
  }

  if (!repositoryUrl) {
    throw new Error("No repository provided");
  }

  if (!analysisSummary) {
    return <LoadingProgress />;
  }

  return (
    <div>
      <Container className={styles.container} maxWidth="md" disableGutters>
        <AnalysisReportIndex className={styles.tableOfContents} />
        <AnalysisReport repositoryUrl={repositoryUrl} analysisSummary={analysisSummary} />
      </Container>
      {/* <a href="https://reviewpad.com/discord" target="_blank" rel="noreferrer" className={styles.takeActionLink}>
        <div className={styles.takeAction}>
          <Typography variant='h6'>Reach out to us to help you understand the results</Typography>
        </div>
      </a> */}
    </div>
  );
}
