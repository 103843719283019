// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import React, { ReactNode, useMemo } from 'react';
import AnalysisClient from '../clients/AnalysisClient';
import ServicesContext from '../contexts/ServicesContext';
import Services from '../models/Services';

export default function ServicesController({ children }: { children: ReactNode }) {
  const value = useMemo(
    () => ({
      analysisService: new AnalysisClient(),
    }) as Services,
    [],
  );

  return (
    <ServicesContext.Provider value={value}>
      {children}
    </ServicesContext.Provider>
  );
}
