// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from "react-apexcharts";
import { plural } from '../../../utils/text';

interface TotalPRsByTotalReviewersChartProps {
  totalPRsByTotalReviewers: Record<string, number>;
}

export default React.memo(function TotalPRsByTotalReviewersChart(props: TotalPRsByTotalReviewersChartProps) {
  const { totalPRsByTotalReviewers } = props;

  const options: ApexOptions = {
    colors: ['#005f73'],
    xaxis: {
      title: {
        text: 'Reviewers count',
      },
      categories: Object.keys(totalPRsByTotalReviewers),
    },
    yaxis: {
      title: {
        text: 'Pull requests count',
      },
    },
    tooltip: {
      x: {
        formatter: (value: number) => `Total number of pull requests with <b>${value} ${plural(Number(value), 'reviewer')}</b>`,
      },
    },
  };

  const series: ApexOptions['series'] = [
    {
      name: "",
      data: Object.values(totalPRsByTotalReviewers),
    }
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
    />
  );
});
