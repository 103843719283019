// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import AnalyticsIcon from '@mui/icons-material/Analytics';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './AnalysisReportIndex.module.css';

const analysisReportMenuItems = [
  {
    title: 'Time',
    id: 'time',
    submenu: [
      {
        title: 'Merge time',
        id: 'merge-time',
      },
      {
        title: 'Review time',
        id: 'review-time',
      },
    ],
  },
  {
    title: 'Size',
    id: 'size',
    submenu: [
      {
        title: 'Pull request size',
        id: 'pull-request-size',
      },
    ],
  },
  {
    title: 'Review Rate',
    id: 'review-rate',
    submenu: [
      {
        title: 'Pull requests count vs Reviewers count',
        id: 'pr-count-vs-reviewers-count',
      },
      {
        title: 'Review count vs Comment count',
        id: 'review-count-vs-comment-count',
      },
    ],
  },
  {
    title: 'Rework Rate',
    id: 'rework-rate',
    submenu: [
      {
        title: 'File count vs Pull requests count',
        id: 'file-count-vs-pr-count',
        icon: AnalyticsIcon,
      },
      {
        title: 'Top modified files',
        id: 'hotspot-files',
        icon: InsertDriveFileIcon,
      },
    ],
  },
];

interface AnalysisReportIndexProps {
  className?: string;
}

export default function AnalysisReportIndex(props: AnalysisReportIndexProps) {
  const { className } = props;
  const location = useLocation();
  const [menuItemSelected, setMenuItemSelected] = useState<string>();

  useEffect(
    () => setMenuItemSelected(location.hash.replace('#', '')),
    [location.hash],
  );

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.menuContainer}>
        {analysisReportMenuItems.map((item, index) => (
          <div className={styles.innerContainer} key={index}>
            <a
              id={`item-${item.id}`}
              href={`#${item.id}`}
              className={clsx(styles.menu, styles.menuItemTitle, menuItemSelected === item.id && styles.selected)}
            >
              <Typography
                variant="h5"
                className={clsx(
                  styles.title,
                  menuItemSelected === item.id && styles.titleSelected
                )}
              >
                {item.title}
              </Typography>
            </a>
            <div className={styles.submenu}>
              {item.submenu.map(submenu => (
                <a
                  key={submenu.id}
                  id={`item-${submenu.id}`}
                  href={`#${submenu.id}`}
                  className={clsx(styles.submenuLink, menuItemSelected === submenu.id && styles.selected)}
                >
                  <Typography variant='subtitle2'>{submenu.title}</Typography>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
