// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { plural } from "./text";

export const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const;

export function getReadableDateAndTime(date: string): string;
export function getReadableDateAndTime(date: Date): string;
export function getReadableDateAndTime(date: string | Date) {
  const mappedDate = date instanceof Date ? date : new Date(date);
  const hour = mappedDate.getHours();
  const minutes = mappedDate.getMinutes();
  const seconds = mappedDate.getSeconds();
  const time = `${hour}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  return `${mappedDate.getDate()} ${month[mappedDate.getMonth()]} ${mappedDate.getFullYear()} ${time}`;
}

export function getReadableDate(date: string): string;
export function getReadableDate(date: Date): string;
export function getReadableDate(date: string | Date) {
  const mappedDate = date instanceof Date ? date : new Date(date);
  return `${mappedDate.getDate()} ${month[mappedDate.getMonth()]} ${mappedDate.getFullYear()}`;
}

export function getReadableTime(time: string) {
  const [hours, minutes] = time.split(':').map(value => parseInt(value));
  var readableTime = ''

  if (hours !== 0) {
    readableTime += `${hours} ${plural(hours, 'hour')}`
  }

  if (minutes !== 0) {
    readableTime += hours !== 0
      ? ` ${minutes} ${plural(minutes, 'minute')}`
      : `${minutes} ${plural(minutes, 'minute')}`
  }

  return readableTime;
}
