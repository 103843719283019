// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import React from 'react';
import Services from '../models/Services';

const ServicesContext = React.createContext<Services>({} as unknown as Services);

export default ServicesContext;
