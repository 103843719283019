// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import config from '../config';
import AnalysisService from '../models/AnalysisService';
import AnalysisSummary from '../models/AnalysisSummary';
import ApiClient from './ApiClient';

export default class AnalysisClient implements AnalysisService {
  client: ApiClient;

  constructor() {
    this.client = new ApiClient({ baseUrl: config.endpoints.analysis });
  }

  async getSummary(repoOwner: string, repoName: string, totalPRs: number, gitHubToken?: string): Promise<AnalysisSummary> {
    const response = await this.client.post('summary', {
      repoOwner,
      repoName,
      totalPRs,
      gitHubToken,
    });

    if (!response.ok) {
      throw new Error('Error trying to get analysis summary');
    }

    const body = await response.json();

    return {
      ...body,
      dateFrom: body.dateFrom.split(" ")[0],
      dateTo: body.dateTo.split(" ")[0],
    }
  }
}
