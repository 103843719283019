// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

type Body = Record<string, any>;

export default class ApiClient {
  private baseUrl: string;

  constructor({ baseUrl }: { baseUrl: string }) {
    this.baseUrl = baseUrl;
  }

  get(path?: string) {
    return this.retrieve('GET', path);
  }

  head(path: string) {
    return this.retrieve('HEAD', path);
  }

  delete(path: string, body?: Body) {
    return this.send('DELETE', path, body);
  }

  post(path: string, body?: Body) {
    return this.send('POST', path, body);
  }

  patch(path: string, body?: Body) {
    return this.send('PATCH', path, body);
  }

  put(path: string, body?: Body) {
    return this.send('PUT', path, body);
  }

  private url(path?: string) {
    const pathname = path ? path.charAt(0) === '?' ? path : `/${path}` : '';
    return `${this.baseUrl}${pathname}`;
  }

  private async fetch(input: RequestInfo, init: RequestInit = {}) {
    const headers = {
      ...init.headers,
      Accept: 'application/json',
    };

    return fetch(input, { ...init, headers });
  }

  private retrieve(method: string, path?: string) {
    return this.fetch(
      this.url(path),
      { method },
    );
  }

  private send(method: string, path: string, body?: Body) {
    if (!body) {
      return this.retrieve(method, path);
    }

    return this.fetch(
      this.url(path),
      {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    );
  }
}
