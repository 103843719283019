// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import CycleType from '../../models/CycleType';
import Percentile from '../../models/Percentile';
import TimePerCycle from '../../models/TimePerCycle';
import { getReadableTime } from '../../utils/date';
import { reviewTimeEvaluation } from '../../utils/metrics';
import { capitalize } from '../../utils/text';
import styles from './TimeByCycle.module.css';

const percentileByOrder: Percentile[] = ['50', '80', '95'];
const cycleTypeByOrder: CycleType[] = ['FIRST_REVIEW', 'FIRST_APPROVAL', 'FINAL_APPROVAL', 'MERGE'];

export function getHighlight(readableAverage: string) {
  const hours = readableAverage.match(/[0-9]+\shour(s?)/) && readableAverage.match(/[0-9]+\shour(s?)/)![0];

  const benchmark = reviewTimeEvaluation(hours ? hours : "0 hours");

  switch (benchmark) {
    case 'ELITE':
      return styles.averageEliteReviewTime;
    case 'STRONG':
      return styles.averageStrongReviewTime;
    case 'FAIR':
      return styles.averageFairReviewTime;
    case 'NEEDS FOCUS':
      return styles.averageNeedsFocusReviewTime;
  }

  return;
}

interface timeByCycleProps {
  averageByCycle: TimePerCycle;
}

export function AverageTimeByCycle(props: timeByCycleProps) {
  const { averageByCycle } = props;
  return (
    <Table>
      <TableBody>
        {cycleTypeByOrder.map((cycleType, index) => {
          const time = averageByCycle[cycleType];
          const readableTime = getReadableTime(time);
          return (
            <TableRow key={index} className={clsx(cycleType === "MERGE" && getHighlight(readableTime))}>
              <TableCell className={styles.tableCell} variant='head'>{capitalize(cycleType.replace('_', ' '))}</TableCell>
              <TableCell className={styles.tableCell} align='right'>
                {readableTime}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

interface TimeByCycleByPercentileProps {
  byPercentile: Record<Percentile, TimePerCycle>;
  totalIgnoredPRs: number;
}

export function TimeByCycleByPercentile(props: TimeByCycleByPercentileProps) {
  const { byPercentile, totalIgnoredPRs } = props;
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Percentile</TableCell>
            <TableCell>First review</TableCell>
            <TableCell>First approval</TableCell>
            <TableCell>Final approval</TableCell>
            <TableCell>Merge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {percentileByOrder.map(percentile => {
            const timeByCycle = byPercentile[percentile];
            return (
              <TableRow key={percentile}>
                <TableCell>
                  <b>{percentile}</b>
                </TableCell>
                {cycleTypeByOrder.map((cycleType, index) => {
                  const time = timeByCycle[cycleType];
                  return (
                    <TableCell key={index}>
                      {getReadableTime(time)}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {totalIgnoredPRs && (
        <Typography variant="caption">
          {totalIgnoredPRs} pull requests ignored due to missed step cycle (i.e. either first review, first approval, or final approval step is missing).
        </Typography>
      )}
    </>
  )
}
