// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import styles from './MetricsBenchmarks.module.css';
import clsx from "clsx";

export default function MetricsBenchmarks() {
  return (
    <>
      <Typography variant='caption'>Engineering Metrics Benchmarks:</Typography>
      <div className={styles.container}>
        <Typography className={styles.benchmark} variant='caption'>
          <CircleIcon className={clsx(styles.circle, styles.eliteBenchmark)} />{' '}Elite team
        </Typography>
        <Typography className={styles.benchmark} variant='caption'>
          <CircleIcon className={clsx(styles.circle, styles.strongBenchmark)} />{' '}Strong team
        </Typography>
        <Typography className={styles.benchmark} variant='caption'>
          <CircleIcon className={clsx(styles.circle, styles.fairBenchmark)} />{' '}Fair team
        </Typography>
        <Typography className={styles.benchmark} variant='caption'>
          <CircleIcon className={clsx(styles.circle, styles.needsFocusBenchmark)} />{' '}Needs focus team
        </Typography>
      </div>
    </>
  );
}
