// Copyright (C) 2019-2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from 'react';
import styles from './Loading.module.css';

export default React.memo(function Loading() {
  return (
    <div className={styles.ripple}>
      <div></div><div></div>
    </div>
  )
});
