// Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import styles from './SectionTitle.module.css';

interface SectionTitleProps {
  id: string;
  title: string;
  subtitle?: string;
}

export default React.memo(function SectionTitle(props: SectionTitleProps) {
  const { id, title, subtitle } = props;
  return (
    <div id={id} className={styles.header}>
      <div className={styles.mainHeader}>
        <IconButton className={styles.anchor} href={`#${id}`}>
          <LinkIcon className={styles.icon} />
        </IconButton>
        <Typography
          variant="h3"
          className={styles.title}
        >
          {title}
        </Typography>
      </div>
      <Typography color="text.secondary">
        {subtitle}
      </Typography>
      <div className={styles.divider} />
    </div>
  );
});
