// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { PathRouteProps } from 'react-router-dom';
import AnalysisComparison from './pages/AnalysisComparison';
import Landing from './pages/Landing';
import SingleAnalysis from './pages/SingleAnalysis';

const routes: PathRouteProps[] = [
  {
    path: '/analysis',
    element: <SingleAnalysis />,
  },
  {
    path: '/comparison',
    element: <AnalysisComparison />,
  },
  {
    path: '/',
    element: <Landing />,
  },
];

export default routes;
