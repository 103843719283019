// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Container } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AnalysisReport from '../../components/AnalysisReport';
import LoadingProgress from '../../components/LoadingProgress';
import { useAnalysisService } from '../../hooks/serviceHooks';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import AnalysisSummary from '../../models/AnalysisSummary';
import styles from './AnalysisComparison.module.css';

export default function AnalysisComparison() {
  const analysisService = useAnalysisService();
  const [searchParams] = useSearchParams();
  const [analysis, setAnalysis] = useState<AnalysisSummary[]>();

  const repositoriesUrl = useMemo(
    () => searchParams.getAll('repository'),
    [searchParams],
  );

  useAsyncEffect(
    async () => {
      if (!repositoriesUrl) {
        return;
      }

      const analysis = await Promise.all(
        repositoriesUrl.map(repository => {
          const url = new URL(repository);
          const [, repoOwner, repoName] = url.pathname.split('/');
          return analysisService.getSummary(repoOwner, repoName, 50);
        })
      );

      setAnalysis(analysis);
    },
    [repositoriesUrl],
  );

  if (!repositoriesUrl.length) {
    throw new Error("No repositories provided");
  }

  if (!analysis) {
    return <LoadingProgress />;
  }

  return (
    <Container className={styles.container} maxWidth={false} disableGutters>
      {analysis.map((report, index) => (
        <AnalysisReport key={repositoriesUrl[index]} repositoryUrl={repositoriesUrl[index]} analysisSummary={report} />
      ))}
    </Container>
  );
}
