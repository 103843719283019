// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import FileChangeDetails from '../../models/FileChangeDetails';
import styles from './HotspotFiles.module.css';

interface HotspotFilesProps {
  hotspotFiles: Record<string, FileChangeDetails>;
}

export default React.memo(function HotspotFiles(props: HotspotFilesProps) {
  const { hotspotFiles } = props;
  const orderedHotspotFilesPath = useMemo(
    () => Object.entries(hotspotFiles)
      .sort(([, fileChangeDetailsA], [, fileChangeDetailsB]) => {
        return fileChangeDetailsB.pullRequests - fileChangeDetailsA.pullRequests;
      })
      .map(([filePath]) => filePath),
    [hotspotFiles],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>File path</TableCell>
          <TableCell align="right">Total additions</TableCell>
          <TableCell align="right">Total deletions</TableCell>
          <TableCell align="right">Size (additions + deletions)</TableCell>
          <TableCell align="right">Total threads</TableCell>
          <TableCell align="right">Total PRs</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderedHotspotFilesPath.map(filePath => {
          const fileChangeDetails = hotspotFiles[filePath];
          return (
            <TableRow key={filePath}>
              <TableCell component="th" scope="row">
                <Tooltip title={filePath} placement="top">
                  <p className={styles.filePath}>{filePath}</p>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                {Math.trunc(fileChangeDetails.additions)}
              </TableCell>
              <TableCell align="right">
                {Math.trunc(fileChangeDetails.deletions)}
              </TableCell>
              <TableCell align="right">
                {Math.trunc(fileChangeDetails.additions + fileChangeDetails.deletions)}
              </TableCell>
              <TableCell align="right">
                {Math.trunc(fileChangeDetails.threads)}
              </TableCell>
              <TableCell align="right">
                {Math.trunc(fileChangeDetails.pullRequests)}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
});
