// Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

const baseApi = process.env.REACT_APP_BASE_API;

const env = {
  endpoints: {
    analysis: `${baseApi}/analysis`,
  }
};

export default env;
